@import './variables.scss';

#portfolio {
	h1.center-title {
		display: block;
		text-align: center;
	}
	main {
		padding-top: 80px;
		.filter-projects-select {
			color: black;
			display: block;
			margin: 0 auto;
		}
	}
	#projects {
		align-items: center;
		align-content: flex-start;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		margin: 0;
		margin-bottom: 50px;
		min-height: 100vh;
		height: 100%;
		width: 100%;
		.project-div {
			position: relative;
			background-color: transparent;
			border: none;
			margin: 0 5px;
			width: 46%;
			span {
				border-radius: 5px 5px 0 0;
				display: block;
				position: relative;
				top: 0;
				right: 0;
				margin-top: 20px;
				width: 100%;
      }
			img {
				border-radius: 0 0 5px 5px;
				position: relative;
				left: 0;
				bottom: 0;
				width: 100%;
      }
    }
  }
}

@media only screen and (min-width: 500px) {
  #portfolio #projects .project-div {
    width: 45%;
  }
}

@media only screen and (min-width: 1200px) {
  #portfolio #projects .project-div {
    width: 20%;
  }
}
