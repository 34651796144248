@import './variables.scss';

#portfolio {
	#header-top {
		align-content: center;
		align-items: center;
		display: flex;
		flex-flow: row;
		justify-content: space-between;
		padding: 10px 0;
    align-self: center;
    justify-self: center;
		width: 100%;
    .button-menu {
      display: block;
      background-color: transparent;
      border: none;
      margin: 0 20px;
      width: 30px;
      img {
        width: 100%;
      }
    }
    #header-menu {
      display: none;
      .nav-link {
        display: block;
        padding: 5px 20px;
      }
      .logged {
        display: flex;
        flex-direction: column;
        button {
          background: transparent;
          border: none;
          &:hover {
            border-left: 1px solid yellow;
            transform: scale(1.1);
          }
        }
      }
    }
    .button-logo {
      background-color: transparent;
      border: none;
      margin: 0 20px;
      font-size: 19px;
    }
  }
}

.nav-link {
	text-decoration: none;
  cursor: pointer;
}


@media only screen and (min-width: 1000px) {
  #portfolio {
    #header-top {
      position: fixed;
      z-index: 30;
      .button-menu {
        display: none;
      }
      #header-menu {
        display: flex;
        .nav-link {
          font-weight: 600;
          padding: 0 50px;
        }
        .dropdown-menu {
          display: flex;
          flex-direction: column;
          background-color: $button-dark;
          .dropdown-item {
            display: block;
            padding: 0 20px;
          }
          .dropdown-header {
            margin: 0 auto;
            text-align: center;
          }
          .slideThree {
            display: flex;
            justify-content: space-around;
            background-color: $button-dark;
            min-height: 50px;
            margin: 0 auto;
            width: 100%;
            .idk {
              border-radius: 20px;
              padding: 5px 0;
              margin: 0 auto;
              position: relative;
              input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;
              }

              .radio {
                border-radius: 100%;
                position: absolute;
                top: 0;
                height: 25px;
                width: 25px;
                &:after {
                  content: "";
                  position: absolute;
                  display: none;
                }
                &.radio-dark {
                  padding: 4px;
                  left: 0;
                }
                &.radio-light {
                  right: 0;
                }
              }
              &:hover input ~ .radio-dark {
                background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(2,0,36,0) 90%, rgba(0,212,255,0) 100%);
              }
              &:hover input ~ .radio-light {
                background: radial-gradient(circle, rgba(245,255,0,1) 0%, rgba(2,0,36,0) 80%, rgba(0,212,255,0) 100%);
              }
              input:checked ~ .radio-dark {
                background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(2,0,36,0) 90%, rgba(0,212,255,0) 100%);
              }
              input:checked ~ .radio-light {
                background: radial-gradient(circle, rgba(245,255,0,1) 0%, rgba(2,0,36,0) 80%, rgba(0,212,255,0) 100%);
              }
              input:checked ~ .radio:after {
                display: block;
              }
              .radio:after {
                left: 9px;
                top: 5px;
                width: 5px;
                height: 10px;
                border-width: 0 3px 3px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
              }
            }
          };
        }
      }
      .button-logo {
        .logo-title {
          font-size: unset();
        }
      }
    }
  }

  #portfolio #header-top h1, #header-top nav {
    margin: 20px 40px;
  }
}
