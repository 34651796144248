#portfolio {
  main.project-individual {
    display: block;
    min-height: 100vh;
    height: 100%;
    padding: 100px 0;
    width: 100%;
    iframe {
      min-height: 100vh;
      height: 100%;
      width: 100%;
    }
    .not-found-body {
      padding: 0;
      margin: 0 auto;
    }
  };
};
