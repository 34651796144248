$button-text: white;
$header-text: white;
$project-link: rgba(290,103,95,1);
$project-link-hover: rgba(290,103,95,1);

// Dark mode colors

$background-dark: hsl(0, 0%, 8%);
$background-project-dark: rgba(40, 40, 40, 0.711);
$button-dark: rgba(290,103,95,1);
$header-dark: black;
$purple-dark: #FEFEFF;
$text-dark: #ccc;
$transition-linear-dark: linear-gradient(0deg, hsl(0, 0%, 8%) 0%, hsl(0, 0% ,15%) 20%, hsl(0, 0%,10%) 80%, hsl(0, 0%, 8%) 100%);

// Light mode colors

$background-light: rgb(245, 245, 245);
$background-project-light: rgba(0, 0, 0, 0.767);
$button-light: rgba(290,103,95,1);
$header-light: hsl(0, 0%, 40%);
$purple-light: rgba(290,103,95,1);
$text-light: black;
$transition-linear-light: linear-gradient(0deg, rgba(31,31,31,0) 0%, rgba(230,230,230,1) 20%, rgba(230,230,230,1) 80%, rgba(54,54,54,0) 100%);

// Configuration of carousel

// Desktop

$skills: 28; // How much skills go be show
$sides:  calc($skills / 2); // Division of skills to speared the sides
$size: 0.15; // Size of skills

// Mobile

$skills-mobile: 28; // How much skills go be show
$sides-mobile:  10; // Division of skills to speared the sides
$size-mobile: 0.1; // Size of skills
