* {
  margin: 0;
  box-sizing: border-box;
}

body, #root, #trybewallet, #drinks-and-foods {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
}

::-webkit-scrollbar {
  display: none;
  background-color: none;
  width: 50px;
}

::-webkit-scrollbar-thumb {
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
  background-color: #AAAAAA;
}

#trybetunes, #trivia {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
}