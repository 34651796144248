@import './variables.scss';

#portfolio {
  #project {
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 0 auto;
    width: 90%;
    .slider-div {
      width: 100%;
      .project-div {
        align-items: center;
        align-content: center;
        background-color: transparent;
        border: none;
        display: flex;
        flex-flow: column;
        justify-content: center;
        width: 100%;
        .project-img {
          width: 100%;
        }
      }
      .link-none {
        text-decoration: none;
        color: inherit;
      }
      .project-img {
        width: 100% !important;
      }
      #project {
        .carousel-arrow {
          display: none;
        }
      }
    }
    .look-all {
      align-self: flex-end;
      background-color: $purple-dark;
      border-radius: 10px;
      display: block;
      color: $header-text;
      padding: 0 10px;
      margin-bottom: 10px;
      text-decoration: none;
      &:hover {
        filter: brightness(0.6);
        font-weight: 600;
      }
    }
  }
}

@media only screen and (min-width: 1000px) {
  #portfolio {
    #project {
      .slider-div {
        .project-div {
          align-items: stretch;
          flex-flow: wrap;
          font-size: 20px;
          font-weight: 500;
          position: relative;
        }
        .carousel-arrow {
          display: flex;
          filter: opacity(0.5);
          transition: 1s;
          &:hover {
            filter: opacity(1);
          }
          &::before {
            border-radius: 100%;
            padding: 5px;
          }
        }
        .carousel-prev {
          border-radius: 5px 0 0 5px;
          &::before {
            background-image: url('../images/icons/arrow-left.svg');
            transform: scale(2);
          }
        }
        .carousel-next {
          border-radius: 0 5px 5px 0;
          &::before {
            background-image: url('../images/icons/arrow-right.svg');
            transform: scale(2);
          }
        }
      }
      .slick-next {
        border-radius: 0 20px 20px 0;
        transform: translateX(0px) translateY(-111px) !important;
        height: 90% !important;
        width: 40px !important;
      }
      .slick-prev {
        border-radius: 20px 0 0 20px;
        transform: translateX(0px) translateY(-111px) !important;
        height: 90% !important;
        width: 40px !important;
        z-index: 30;
      }
      .slide {
        &.demo {
          div {
            text-align: center;
          }
        }
      }
    }
  }
}
