@import './variables.scss';

#portfolio {
	#footer {
		align-content: center;
		align-items: center;
		bottom: 0;
		color: $header-text;
		display: flex;
		justify-content: center;
		flex-flow: column nowrap;
		font-size: 15px;
		text-align: center;
		padding: 0 5px;
		width: 100%;
		a {
			color: $header-text;
		}
	}
	.rede-social {
		margin: 10px 15px;
		width: 25px;
		&:hover {
			transform: translateY(-5px);
		}
		&:active {
			transform: translateY(5px);
		}
		&.git {
			background-color: white;
			border-radius: 100%;
		}
	}
}


@media only screen and (min-width: 1000px) {
	#portfolio {
		#footer {
			flex-flow: row wrap;
			padding: 10px;
		}
		.rede-social {
			margin: 0 15px;
		}
	}
}
