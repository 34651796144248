@import './variables.scss';

#portfolio {
	#home {
		display: flex;
		flex-direction: column;
		justify-content: center;
    text-align: center;
    padding-top: 30px;
    margin: 0 auto;
    width: 100%;
		h2 {
			font-size: 30px;
    }
		.home-word-p {
			font-size: 15px;
			&.display-show-more {
				animation: showAnimation 0.5s linear both;
      }
			&.hidden-show-more {
				display: none;
      }
    }
		.show-more {
			border-radius: 10px;
      display: block;
      margin: 0 auto;
    }
		.eu {
			border-radius:  50% 50% 100% 100%;
			filter: saturate(1.5);
			margin: 40px 20px;
      width: 50%;
    }
  }
}

@media only screen and (min-width: 1000px) {
  #portfolio {
    #home {
      align-items: center;
      justify-content: space-between;
      text-align: start;
      padding-top: 100px;
      position: relative;
      width: 90%;
      .text-body-1 {
        align-items: center;
        display: flex;
        flex-direction: row-reverse;
        margin: 20px 0;
        width: 80%;
        .home-word-p {
          padding-right: 15px;
        }
      }
      .text-body-2 {
        align-items: center;
        display: flex;
        flex-direction: row;
        width: 80%;
        .home-word-p {
          padding-left: 15px;
        }
      }
      .home-word-p {
        font-size: 20px;
        &.display-show-more {
          display: block;
          transform: scale(1);
        } 
        &.hidden-show-more {
          display: block;
          transform: scale(1);
        }
      }
      .show-more {
        display: none;
      }
      .eu {
        width: none;
        height: 400px;
      }
    }
  }
}

@keyframes showAnimation {
  0% { 
    display: inline;
    transform: scale(1, 0);
  }
  100% { 
    display: inline;
    transform: scale(1);
  }
};
