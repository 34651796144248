@import './variables.scss';

#portfolio-contact {
	padding: 20px;
	padding-top: 100px;
	text-align: center;
	width: 100%;
	.contact-body {
		display: flex;
		justify-content: center;
		flex-direction: column-reverse;
		width: 100%;
		form {
			align-items: center;
			display: flex;
			flex-direction: column;
			margin: 0 auto;
			width: 100%;
			label {
				align-items: center;
				display: flex;
				flex-direction: column;
				width: 100%;
				input, textarea {
					color: black;
				}
			}
			textarea {
				height: 200px;
			}
			div {
				&.button-form-contact {
					align-items: center;
					display: flex;
					justify-content: center;
					margin: 10px 0;
					position: relative;
					width: 100%;
				}
			}
			button {
				border: none;
				border-radius: 10px;
				font-weight: 600;
				margin: 0 20px;
				width: 100%;
				&:disabled {
					cursor: not-allowed;
					opacity: 0.2;
				}
				&:hover {
					opacity: 0.5;
				}
			}
			svg {
				position: absolute;
				right: 0;
			}
		}
		.contact-body-others { 
			align-items: center;
			display: flex;
			flex-direction: column;
			flex-wrap: wrap;
			justify-content: flex-start;
			text-align: center;
			width: 100%;
			div.other-contacts {
				display: flex;
				justify-content: space-around;
				width: 100%;
				div {
					align-items: flex-start;
					display: flex;
					flex-direction: column;
					width: 100%;
					img {
						width: 30px;
					}
					a {
						align-items: center;
						border-radius: 10px;
						display: flex;
						font-size: 17px;
						font-weight: 400;
						text-decoration: none;
						margin: 20px 0;
						width: 0;
						img {
							margin: 0 10px;
						}
		
						&:hover {
							font-weight: 600;
							transition: 1s;
							width: 100%;
						}
					}
					&.right-side {
						align-items: flex-end;
						a {
							flex-direction: row-reverse;
						}
					}
				}
			}
			.center-title {
				color: $text-dark;
			}
    }
  }
}

#portfolio-contact form input, #portfolio-contact form textarea {
	border: none;
	border-radius: 10px;
	width: 100%;
}

#portfolio .contact-page {
	padding: 0;
	margin: 0;
	.contact-body {
		margin: 0;
		padding: 0;
		min-height: 100vh;
		height: 100%;
	}
}

@media only screen and (min-width: 1000px) {
	#portfolio-contact {
		.contact-body {
			flex-direction: row;
			margin: 0 auto;
			width: 90%;
			form {
				width: 30%;
				button {
					width: 50%;
				}
			}
		}
		.contact-body-others { 
			align-items: flex-start;
			flex-direction: row-reverse;
			margin: 0 auto;
			width: 35% !important;
			div.other-contacts {
				a {
					color: inherit;
					&:hover {
						width: 50%;
					}
				}
			}
    }
  }
	#portfolio .contact-page {
		.contact-body {
			margin: 20px auto;
		}
	}
}
