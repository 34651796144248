.not-found-body {
  align-items: center;
  background: linear-gradient(0deg, rgba(31,31,31,0) 0%, rgba(230,230,230,1) 20%, rgba(230,230,230,1) 80%, rgba(54,54,54,0) 100%);
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  margin: 200px auto;
  width: 90%;
}

.not-found-text {
  text-align: center;
  width: 50%;
}

.not-found-p {
  font-size: 17px;
  font-weight: 400;
}
